<script lang="tsx">
import { defineComponentBaseUiButton } from '@core/app/components/base/ui/BaseUiButton.vue'

type Colors = 'primary' | 'secondary' | 'transparent' | 'white' | 'gray' | 'black' | 'none'
type Variants = 'plain' | 'sharp' | 'outlined' | 'solid' | 'bare' | 'bare-aligned'
type Sizes = 'small' | 'medium' | 'large' | 'auto'

export default defineComponentBaseUiButton<Colors, Variants, Sizes>({
    props: {
        size: {
            default: 'medium',
        },
        color: {
            default: 'primary',
        },
        variant: {
            default: 'solid',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton.scss" as *;

@include content {
    gap: 0.375rem;

    @include more-than('sm') {
        gap: 0.75rem;
    }
}

@include button {
    font-size: 0.875rem;
    line-height: 100%;
    font-weight: 400;
    letter-spacing: $vut-letter-spacing-large;
    text-transform: uppercase;
    font-family: $vut-ff-secondary;

    border-radius: $vut-border-radius;

    transition: background-color $vut-trans-time $vut-timing, color $vut-trans-time $vut-timing, border $vut-trans-time $vut-timing, opacity $vut-trans-time $vut-timing;

    &:focus-within {
        border-radius: 0; // Adjusting because the focus outline appears visually more rounded when border-radius is set to 1px

        z-index: 1;
    }
}

// Disabled button
@include button--disabled {
    background-color: $vut-c-gray;
    opacity: 0.5;

    @include button--variant(('bare', 'bare-aligned'), '&') {
        background-color: transparent;
        color: $vut-c-gray;
    }
}

@include button--variant(outlined) {
    border: 2px solid var(--outline-color);

    @include content {
        margin: -2px;
    }
}

@include button--variant(plain) {
    border: 2px solid var(--outline-color);

    @include content {
        margin: -2px;
    }
}

@include button--variant(sharp) {
    border-radius: 0;
}

// --------------------------------------------------------------------
// Button sizes
@include button--size(auto) {
    padding: 0;

    height: 100%;
    width: auto;
}

@include button--size(small) {
    padding: 0.8125rem 1.875rem;
}

@include button--size(medium) {
    padding: 1.125rem 2.5rem;
}

@include button--size(large) {
    padding: 1.5625rem 2.5rem;
}

@include button--variant('bare-aligned') {
    padding-inline: 0;
}

// --------------------------------------------------------------------
// Button colors
@include button--color('primary') {
    @include button--variant(solid, '&') {
        background-color: $vut-c-primary;
        color: $vut-c-white;

        @include button--hover('&') {
            background-color: $vut-c-primary-darker;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $vut-c-white;
        color: $vut-c-primary;

        --outline-color: #{$vut-c-primary};

        @include button--hover('&') {
            background-color: $vut-c-primary;
            color: $vut-c-white;
        }
    }

    @include button--variant(bare, '&') {
        background-color: transparent;
        color: $vut-c-primary;

        --outline-color: transparent;

        @include button--hover('&') {
            --outline-color: transparent;
            color: $vut-c-primary-darker;
        }
    }
}

@include button--color('secondary') {
    @include button--variant(('solid', 'sharp'), '&') {
        background-color: $vut-c-secondary;
        color: $vut-c-white;

        @include button--hover('&') {
            background-color: $vut-c-secondary-darker;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $vut-c-white;
        color: $vut-c-secondary;

        --outline-color: #{$vut-c-secondary};

        @include button--hover('&') {
            background-color: $vut-c-secondary;
            color: $vut-c-white;
        }
    }

    @include button--variant(plain, '&') {
        background-color: $vut-c-white;
        color: $vut-c-secondary;

        --outline-color: #{$vut-c-white};

        @include button--hover('&') {
            --outline-color: #{$vut-c-secondary};
        }
    }

    @include button--variant(('bare', 'bare-aligned'), '&') {
        background-color: transparent;
        color: $vut-c-secondary;

        --outline-color: transparent;

        @include button--hover('&') {
            --outline-color: transparent;
            color: $vut-c-secondary-darker;
        }
    }
}

@include button--color('transparent') {
    background-color: transparent;
    color: $vut-c-secondary;

    @include button--hover('&') {
        background-color: $vut-c-secondary;
        color: $vut-c-white;
    }
}

@include button--color('white') {
    background-color: $vut-c-white;

    @include button--hover('&') {
        background-color: $vut-c-ivory;
        color: $vut-c-secondary;
    }

    @include button--variant(outlined, '&') {
        border: 1px solid $vut-c-border;

        @include content {
            margin: -1px;
        }
    }
}

@include button--color('black') {
    background-color: $vut-c-black;
    color: $vut-c-white;

    @include button--hover('&') {
        background-color: $vut-c-secondary;
        color: $vut-c-white;
    }
}

@include button--color('gray') {
    color: $vut-c-white;
    background-color: $vut-c-gray;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}
</style>
