<script lang="tsx">
import type { ComponentInstance, FunctionalComponent, SlotsType } from 'vue'
import type { NuxtLinkProps } from '#app'
import type { MaybePromise } from 'rollup'
import type {
    ButtonVariants,
    ComponentOverrideOptions,
    SizeProp
} from '@core-types/components'
import { NuxtLink, CoreIconLoadingSpinner } from '#components'

export type BaseUiButtonProps<Colors extends string, Variants extends string, Sizes extends string> = {
    contentClass?: string

    type?: 'button' | 'submit' | 'reset'

    color?: Colors
    variant?: Variants
    size?: Sizes
    align?: 'left' | 'center' | 'right' | 'between'

    dense?: boolean
    square?: boolean
    pill?: boolean

    loading?: boolean
    disabled?: boolean | 'silently'
    /**
     * Controls whether to show a loading spinner when the button is in a loading state.
     * If disabled, the button will load silently without showing the spinner or disabled styles.
     *
     * However, when loading silently, the button remains clickable until it's clicked once again during loading.
     * In that case:
     * - the spinner will appear.
     * - the button element will be disabled and non-clickable until the loading completes.
     * - the onClick callback will be triggered automatically after the first callback finishes and only after
     *   the second callback finishes, the button will be enabled again and the spinner will disappear.
     *
     * This option is suitable for optimistic updates.
     * @default true
     */
    showLoading?: boolean

    onClick?: () => MaybePromise<any>

    to?: NuxtLinkProps['to'] | null
    activeClass?: NuxtLinkProps['activeClass']
    exactActiveClass?: NuxtLinkProps['exactActiveClass']
    external?: NuxtLinkProps['external']
    target?: NuxtLinkProps['target']
    ariaLabel?: string
    ariaDescribedBy?: string
}

type BaseUiButtonSlots<Colors extends string, Variants extends string, Sizes extends string> = {
    default: {}
    leading: {}
    trailing: {}
    leadingSprite: {
        loaderClass: string
    }
}

type ComponentOptions = {}

export function defineComponentBaseUiButton<
    Colors extends string,
    Variants extends string = ButtonVariants,
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiButtonProps<Colors, Variants, Sizes>, BaseUiButtonSlots<Colors, Variants, Sizes>>) {
    return defineComponent(
        (props: BaseUiButtonProps<Colors, Variants, Sizes>, ctx) => {

            const tag = computed(() => props.to ? MutableNuxtLink as ComponentInstance<any> : 'button')
            const isLink = computed(() => props.to !== undefined)

            const { injected: baseUiElementGroupInjected } = useBaseUiElementGroupProvide()

            const internalLoading = ref<boolean>(false)
            const isLoading = computed(() => props.loading || internalLoading.value)
            const isDisabled = computed(() => props.disabled || isLoadingVisible.value)

            const shouldCallAfterLoaded = ref<boolean>(false)
            const isLoadingVisible = computed(() => isLoading.value && (props.showLoading || shouldCallAfterLoaded.value))
            const isDisabledVisible = computed(() => isDisabled.value && props.disabled !== 'silently' && props.showLoading && !shouldCallAfterLoaded.value)

            const parentScope = getScopeIdAttr()

            async function handleButtonClick() {
                if (isLoading.value && !props.showLoading) {
                    shouldCallAfterLoaded.value = true
                    return
                }

                if (isDisabled.value) return

                if (props.onClick) {
                    const maybePromise = props.onClick()
                    if (maybePromise instanceof Promise) {
                        internalLoading.value = true

                        try {
                            await maybePromise
                        } finally {

                            if (shouldCallAfterLoaded.value) {
                                const maybePromise = props.onClick()
                                if (maybePromise instanceof Promise) {
                                    await maybePromise.catch()
                                }
                            }

                            internalLoading.value = false
                            shouldCallAfterLoaded.value = false
                        }
                    }
                }
            }

            return () => (
                <tag.value {...{
                    'class': ['sim-btn', baseUiElementGroupInjected?.classes.value, {
                        [`sim-btn--c-${props.color}`]: props.color,
                        [`sim-btn--s-${props.size}`]: props.size,
                        [`sim-btn--v-${props.variant}`]: props.variant,
                        'sim-btn--pill': props.pill,
                        'sim-btn--dense': props.dense,
                        'sim-btn--square': props.square,
                        'sim-btn--loading': isLoadingVisible.value,
                        'sim-btn--disabled': isDisabledVisible.value,
                        'sim-btn--disabled-silent': isDisabled.value && props.disabled === 'silently',
                    }],
                    'disabled': isDisabled.value,
                    ...(isLink.value ? {
                        to: props.to,
                        external: props.external,
                        target: props.target,
                        activeClass: props.activeClass,
                        exactActiveClass: props.exactActiveClass,
                        onClick: props.onClick ? handleButtonClick : undefined,
                    } : {
                        type: props.type,
                        onClick: handleButtonClick,
                    }),
                    'aria-label': props.ariaLabel,
                    'aria-describedby': props.ariaDescribedBy,
                }}>
                    <span {...{
                        style: isLoadingVisible.value ? 'visibility: hidden' : undefined,
                        class: ['sim-btn__content', {
                            'justify-start': props.align === 'left',
                            'justify-center': props.align === 'center',
                            'justify-end': props.align === 'right',
                            'justify-between': props.align === 'between',
                            [`${props.contentClass}`]: props.contentClass,
                        }],
                        ...parentScope,
                    }}>
                        {
                            // LEADING SLOT
                            (ctx.slots.leading !== undefined || options?.slots?.leading) && (
                                <span class="sim-btn__leading" aria-hidden="true">
                                    { renderSlot(ctx.slots.leading, options?.slots?.leading, {} as never) }
                                </span>
                            )
                        }

                        {
                            // DEFAULT SLOT
                            renderSlot(ctx.slots.default, options?.slots?.default, {})
                        }

                        {
                            // TRAILING SLOT
                            (ctx.slots.trailing !== undefined || options?.slots?.trailing) && (
                                <span class="sim-btn__trailing" aria-hidden="true">
                                    { renderSlot(ctx.slots.trailing, options?.slots?.trailing, {} as never) }
                                </span>
                            )
                        }
                    </span>

                    {
                        isLoadingVisible.value && renderSlot(ctx.slots.leadingSprite, options?.slots?.leadingSprite, {
                            loaderClass: 'loaderClass',
                        }, (
                            <CoreIconLoadingSpinner class="sim-btn__loader" />
                        ))
                    }
                </tag.value>
            )
        },
        {
            props: defineRuntimeProps<BaseUiButtonProps<Colors, Variants, Sizes>>({
                contentClass: { type: String },
                // @ts-ignore
                type: { type: String, default: 'button' },
                // @ts-ignore
                color: { type: String },
                // @ts-ignore
                variant: { type: String },
                // @ts-ignore
                size: { type: String },
                // @ts-ignore
                align: { type: String, default: 'center' },
                dense: { type: Boolean },
                square: { type: Boolean },
                pill: { type: Boolean },
                loading: { type: Boolean },
                showLoading: { type: Boolean, default: true },
                // @ts-ignore
                disabled: { type: [Boolean, String] },
                // @ts-ignore
                onClick: { type: Function },
                to: { type: String },
                activeClass: { type: String },
                exactActiveClass: { type: String },
                external: { type: Boolean },
                target: { type: String },
                ariaLabel: { type: String },
                ariaDescribedBy: { type: String },

            }, options),
            slots: Object as SlotsType<BaseUiButtonSlots<Colors, Variants, Sizes>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiButton()

const MutableNuxtLink: FunctionalComponent<{
    disabled?: boolean,
    to: NuxtLinkProps['to']
    target?: NuxtLinkProps['target']
    external?: NuxtLinkProps['external']
    activeClass?: NuxtLinkProps['activeClass']
    exactActiveClass?: NuxtLinkProps['exactActiveClass']
}> = (props, { slots, emit, attrs }) => {
    if (props.disabled) {
        return h('div', {
            ...attrs,
            class: 'cursor-not-allowed inline-block',
        }, h(NuxtLink, {
            to: props.to ?? '',
            target: props.target,
            external: props.external,
            activeClass: props.activeClass,
            exactActiveClass: props.exactActiveClass,
            class: 'pointer-events-none',
            tabindex: -1,
        }, () => [
            slots.default?.(),
        ])
        )
    }

    return h(NuxtLink, {
        to: props.to ?? '',
        target: props.target,
        external: props.external,
        activeClass: props.activeClass,
        exactActiveClass: props.exactActiveClass,
        class: 'inline-block',
    }, () => [
        slots.default?.(),
    ])
}
MutableNuxtLink.props = {
    disabled: { type: Boolean },
    to: { type: String, required: true },
    target: { type: String },
    external: { type: Boolean },
    activeClass: { type: String },
    exactActiveClass: { type: String },
}

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton.scss" as *;

</style>
